import React from "react";
import Navbar from "./Navbar";
import { Box } from "@mui/material";
import Sidebar from "./Sidebar";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import Login from "./Login";
import LoginPage from "../pages/LoginPage";

const Layout = ({ children }) => {
  return (
    <>
      <AuthenticatedTemplate>
        <Navbar />
        <Box height={64} />
        <Box sx={{ display: "flex" }}>
          <Sidebar />
          <Box
            sx={{
              flex: 1,
              p: 3,
              backgroundColor: "#F6F6F6",
              minHeight: `calc(100vh - 64px)`,
              width: "100%",
              overflowX: "hidden",
            }}
          >
            {children}
          </Box>
        </Box>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginPage />
      </UnauthenticatedTemplate>
    </>
  );
};

export default Layout;
