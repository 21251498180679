export const msalConfig = {
  auth: {
    clientId: "cff0788c-cd19-4d91-831f-d392b03b58f7",
    authority:
      "https://login.microsoftonline.com/5a45fe0f-b0b1-44ab-a854-96da61fb8e8e",
    redirectUri: "https://witty-ground-0b5305e10.4.azurestaticapps.net/",
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
