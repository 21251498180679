import { Copyright } from "@mui/icons-material";
import { Avatar, Box, Button, Paper, Typography } from "@mui/material";
import React from "react";
import Login from "../components/Login";

const LoginPage = () => {
  return (
    <Box
      component="main"
      maxWidth="xs"
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 10,
          gap: 2,
        }}
      >
        <Box
          component="img"
          sx={{
            height: 52,
          }}
          src="/assets/wisp_logo.svg"
        />

        <Login />
      </Paper>
    </Box>
  );
};

export default LoginPage;
