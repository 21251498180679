import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  Pagination,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import { Search } from "@mui/icons-material";
import constants from "../utils/constants";
import AgentCard1 from "./AddAgentCard";
import AgentCard from "./AgentCard";
import { useTheme } from "@emotion/react";
import AddAgentCard from "./AddAgentCard";

const AgentsList = () => {
  const theme = useTheme();
  const [agents, setAgents] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filteredAgents, setFilteredAgents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [agentsPerPage, setAgentsPerPage] = useState(6);
  const [searchQuery, setSearchQuery] = useState("");
  const [error, setError] = useState(null);

  // Use media query to check the screen size at 'md' breakpoint
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));

  // Effect to run when the screen size changes
  useEffect(() => {
    // Adjust the state based on the screen size

    if (isExtraSmallScreen) {
      setAgentsPerPage(2);
    } else if (isSmallScreen) {
      setAgentsPerPage(4);
    } else if (isMediumScreen) {
      setAgentsPerPage(4);
    } else if (isLargeScreen) {
      setAgentsPerPage(6);
    } else if (isExtraLargeScreen) {
      setAgentsPerPage(9);
    } else {
      setAgentsPerPage(6);
    }
  }, [
    isExtraSmallScreen,
    isSmallScreen,
    isMediumScreen,
    isLargeScreen,
    isExtraLargeScreen,
  ]); // Re-run the effect when screen sizes change

  const fetchAgents = async () => {
    try {
      setLoading(true);
      const url = `${constants.backend_url}/agents/`;
      const res = await axios.get(url);
      setAgents([null, ...res.data]);
      setFilteredAgents([null, ...res.data]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
      console.log(error.response);
    }
  };
  useEffect(() => {
    fetchAgents();
  }, []);

  const handleDeleteAgent = async (id) => {
    try {
      let url = `${constants.backend_url}/agents/${id}`;
      let res = await axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      await fetchAgents();
    } catch (error) {
      setError(error);
      console.log(error.response);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();
    setSearchQuery(value);
    let result = [];
    result = agents.filter((agent) => {
      if (agent) {
        const searchRegex = new RegExp(value, "i");
        return (
          agent.agent_description.search(searchRegex) !== -1 ||
          agent.endpoint.search(searchRegex) !== -1 ||
          agent.name.search(searchRegex) !== -1 ||
          agent.function.search(searchRegex) !== -1
        );
      }
    });
    if (value === "") {
      setFilteredAgents([null, ...result]);
    } else {
      setFilteredAgents(result);
    }
    setCurrentPage(1);
  };

  function getCurrentAgents(agents) {
    const indexOfLastAgent = currentPage * agentsPerPage;
    const indexOfFirstAgent = indexOfLastAgent - agentsPerPage;
    let currentPageAgents = agents.slice(indexOfFirstAgent, indexOfLastAgent);
    return currentPageAgents;
  }

  if (error) {
    return <Typography>Error Occured. Please try Again</Typography>;
  }

  if (loading) {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 4,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <TextField
          size="small"
          placeholder="Search Agent"
          variant="outlined"
          value={searchQuery}
          onChange={(event) => handleSearch(event)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          sx={{ backgroundColor: "white" }}
        />
      </Box>
      <Grid
        container
        direction="row"
        spacing={{ xs: 2, md: 4 }}
        sx={{ flex: 1 }}
      >
        {getCurrentAgents(filteredAgents).map((agent, index) => {
          if (agent !== null) {
            return (
              <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                <Box sx={{ height: "100%" }}>
                  <AgentCard
                    agent={agent}
                    handleDeleteAgent={handleDeleteAgent}
                  />
                </Box>
              </Grid>
            );
          } else {
            return (
              <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                <Box sx={{ height: "100%" }}>
                  <AddAgentCard />
                </Box>
              </Grid>
            );
          }
        })}
      </Grid>

      <Pagination
        onChange={handlePageChange}
        count={Math.ceil(filteredAgents.length / agentsPerPage)}
        page={currentPage}
        color="primary"
        variant="outlined"
        sx={{ display: "flex", justifyContent: "flex-end" }}
      />
    </Box>
  );
};

export default AgentsList;
