import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import { Box, CircularProgress, Typography } from "@mui/material";
import Navbar from "../components/Navbar";
import { useAppStore } from "../stores/appStore";
import AddAutomationForm from "../components/AddAutomationForm";
import { useParams } from "react-router-dom";
import constants from "../utils/constants";
import axios from "axios";

const AddAutomation = () => {
  const updatePageOpen = useAppStore((state) => state.updatePageOpen);
  const [automation, setAutomation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    updatePageOpen("add_automation");
  }, []);

  const params = useParams();

  useEffect(() => {
    const fetchAutomation = async () => {
      try {
        setLoading(true);
        const url = `${constants.backend_url}/automations/${params.automation_id}`;
        const res = await axios.get(url);

        setAutomation(res.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(error);
        console.log(error.response);
      }
    };

    if (Object.keys(params).includes("automation_id")) {
      fetchAutomation();
    }
  }, [params]);

  if (error) {
    return <Typography>Error Occured. Please try Again</Typography>;
  }

  if (loading && !automation) {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (Object.keys(params).includes("automation_id")) {
    return (
      <>
        <Box sx={{ height: "100%" }}>
          {automation && (
            <AddAutomationForm automation={automation} isEdit={true} />
          )}
        </Box>
      </>
    );
  }

  return (
    <>
      <Box sx={{ height: "100%" }}>
        <AddAutomationForm />
      </Box>
    </>
  );
};

export default AddAutomation;
