import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import InputIcon from "@mui/icons-material/Input";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import constants from "../utils/constants";
import axios from "axios";
import { Edit } from "@mui/icons-material";
import { generateColor } from "../utils/generateRandomColor";

const AddAutomationForm = ({ automation = {}, isEdit = false }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    reValidateMode: "onChange",
    defaultValues: {
      parameters: [{ name: "", description: "" }],
      ...automation,
    },
  });

  const {
    fields: parameters,
    append: appendParameter,
    remove: removeParameter,
  } = useFieldArray({
    control,
    name: "parameters",
  });
  // console.log(errors);
  const handleAddNewParameter = () => {
    appendParameter({ name: "", description: "" });
  };

  const handleRemoveParameter = (index) => {
    removeParameter(index);
  };

  const handleOnSubmit = async (evt) => {
    try {
      setLoading(true);

      if (isEdit) {
        let url = `${constants.backend_url}/automations/${automation._id}`;
        let res = await axios.put(url, evt, {
          headers: {
            "Content-Type": "application/json",
          },
        });
      } else {
        let url = `${constants.backend_url}/automations/`;
        let res = await axios.post(url, evt, {
          headers: {
            "Content-Type": "application/json",
          },
        });
      }
      setLoading(false);
      navigate("/");
    } catch (error) {
      setLoading(false);
      setError(error);
      console.log(error.response);
    }
    console.log(evt);
  };

  const [tags, setTags] = useState([]);
  const [tagsLoading, setTagsLoading] = useState(true);
  const [tagsError, setTagsError] = useState(null);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        setTagsLoading(true);
        const url = `${constants.backend_url}/tags/`;
        const res = await axios.get(url);
        let tagNames = res.data.map((tag) => tag.name);
        setTags(tagNames);
        setTagsLoading(false);
      } catch (error) {
        setTagsLoading(false);
        setTagsError(error);
        console.log(error.response);
      }
    };
    fetchTags();
  }, []);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(handleOnSubmit)}
      sx={{ display: "flex", flexDirection: "column", gap: 4 }}
    >
      <Grid container direction="row" spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography>Automation Name</Typography>
            <Controller
              control={control}
              name="name"
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  size="small"
                  placeholder="Click to enter automation name"
                  variant="outlined"
                  sx={{ backgroundColor: "white" }}
                  error={errors.name !== undefined}
                />
              )}
            />
            {errors.name !== undefined && (
              <Typography variant="body2" color="red">
                Automation name is required
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="subtitle1">Automation Description</Typography>
            <Controller
              control={control}
              name="description"
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  size="small"
                  placeholder="Click to enter automation description"
                  variant="outlined"
                  sx={{ backgroundColor: "white" }}
                  error={errors.description !== undefined}
                />
              )}
            />
            {errors.description !== undefined && (
              <Typography variant="body2" color="red">
                Automation description is required
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="subtitle1">Automation Endpoint</Typography>
            <Controller
              control={control}
              name="endpoint"
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  size="small"
                  placeholder="Click to enter automation endpoint"
                  variant="outlined"
                  sx={{ backgroundColor: "white" }}
                  error={errors.endpoint !== undefined}
                />
              )}
            />
            {errors.endpoint !== undefined && (
              <Typography variant="body2" color="red">
                Automation endpoint is required
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="subtitle1">Automation Tags</Typography>

            <Controller
              control={control}
              name="tags"
              // defaultValue={isEdit ? [...automation.tags] : []}
              rules={{ required: true }}
              render={({ field: { ref, onChange, ...field } }) => (
                <Autocomplete
                  multiple
                  options={tags}
                  loading={tagsLoading}
                  defaultValue={isEdit ? [...automation.tags] : []}
                  // getOptionLabel={(option) => option.name}
                  onChange={(_, data) => onChange(data)}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...field}
                      {...params}
                      size="small"
                      placeholder="Select automation tags"
                      inputRef={ref}
                      variant="outlined"
                      sx={{ backgroundColor: "white" }}
                      error={errors.tags !== undefined || tagsError !== null}
                    />
                  )}
                  renderTags={(value, getTagProps) => {
                    return value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={
                          <Typography variant="p" fontWeight="bold">
                            {option}
                          </Typography>
                        }
                        size="small"
                        sx={{
                          backgroundColor: generateColor(option),
                          color: "white",
                        }}
                        {...getTagProps({ index })}
                      />
                    ));
                  }}
                />
              )}
            />
            {errors.tags !== undefined && (
              <Typography variant="body2" color="red">
                Automation Tags are required
              </Typography>
            )}
            {tagsError !== null && (
              <Typography variant="body2" color="red">
                Failed to fetch tags
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Typography variant="h5" sx={{ fontFamily: "glancyrregular" }}>
          Input Parameters
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Grid container direction="row" spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle1">Parameter Name</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle1">Parameter Description</Typography>
            </Grid>
          </Grid>
          {parameters.map((field, index) => (
            <Grid
              key={field.id}
              container
              direction="row"
              spacing={{ xs: 2, md: 3 }}
            >
              <Grid item xs={12} md={4}>
                <Controller
                  control={control}
                  // must use . for the object key!!!
                  name={`parameters.${index}.name`}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      placeholder="Click to enter parameter name"
                      variant="outlined"
                      sx={{ backgroundColor: "white" }}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  control={control}
                  // must use . for the object key!!!
                  name={`parameters.${index}.description`}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Box sx={{ display: "flex" }}>
                      <TextField
                        fullWidth
                        {...field}
                        size="small"
                        placeholder="Click to enter parameter description"
                        variant="outlined"
                        sx={{ backgroundColor: "white" }}
                      />
                    </Box>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<DeleteIcon />}
                  onClick={() => handleRemoveParameter(index)}
                >
                  Delete
                </Button>
              </Grid>
            </Grid>
          ))}
        </Box>

        <Button
          variant="contained"
          startIcon={<InputIcon />}
          onClick={handleAddNewParameter}
          sx={{ minWidth: "max-content", width: "20%" }}
        >
          Add Parameter
        </Button>
        {errors.parameters !== undefined && (
          <Typography variant="body2" color="red">
            Add parameters or delete them
          </Typography>
        )}
      </Box>

      <Button
        variant="contained"
        type="submit"
        startIcon={isEdit ? <Edit /> : <AddCircleOutlineIcon />}
        sx={{ minWidth: "max-content", width: "20%" }}
      >
        {isEdit ? "Save Automation" : "Add Automation"}
      </Button>
    </Box>
  );
};

export default AddAutomationForm;
