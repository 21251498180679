import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppStore } from "../stores/appStore";
import { Controller, useForm } from "react-hook-form";
import CreateIcon from "@mui/icons-material/Create";
import { generateColor } from "../utils/generateRandomColor";
import constants from "../utils/constants";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import { Navigate, useNavigate } from "react-router-dom";
let tagNames;
const CreateTags = () => {
  const updatePageOpen = useAppStore((state) => state.updatePageOpen);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    reValidateMode: "onChange",
  });

  const [loading, setLoading] = useState(false);

  const [tags, setTags] = useState([]);
  const [tagsLoading, setTagsLoading] = useState(true);
  const [tagsError, setTagsError] = useState(null);
  const navigate = useNavigate();

  const fetchTags = async () => {
    try {
      setTagsLoading(true);
      const url = `${constants.backend_url}/tags/`;
      const res = await axios.get(url);
      tagNames = res.data.map((tag) => tag.name);
      setTags(tagNames);
      setTagsLoading(false);
    } catch (error) {
      setTagsLoading(false);
      setTagsError(error);
      console.log(error.response);
    }
  };

  useEffect(() => {
    updatePageOpen("settings");
    fetchTags();
  }, []);

  const handleTagsSubmit = async (event) => {
    let createTagReq = [];
    //filter out tags that already exist
    event.tags.forEach((tag) => {
      if (!tagNames.includes(tag)) {
        createTagReq.push({ name: tag });
      }
    });
    // createTagReq = event.tags.map((tag) => ({
    //   name: tag,
    // }));
    if (createTagReq.length === 0) {
      alert("Tags already exist");
      return;
    }
    try {
      setLoading(true);
      let url = `${constants.backend_url}/tags/`;
      await axios.post(url, createTagReq, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (window.confirm("Tags created sucessfully")) {
        window.location = "/settings";
      }
    } catch (error) {
      console.log(error.response.data);
    } finally {
      await fetchTags();
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{ display: "flex", gap: 2, width: "100%", alignItems: "center" }}
      component="form"
      onSubmit={handleSubmit(handleTagsSubmit)}
    >
      <Typography
        variant="body1"
        color="text.secondary"
        sx={{ fontFamily: "glancyrregular" }}
      >
        Create Tags
      </Typography>

      <Controller
        control={control}
        name="tags"
        rules={{ required: true }}
        render={({ field: { ref, onChange, ...field } }) => (
          <Autocomplete
            multiple
            freeSolo
            options={[]}
            onChange={(_, data) => onChange(data)}
            size="small"
            sx={{ flex: 1 }}
            renderInput={(params) => (
              <TextField
                {...field}
                {...params}
                size="small"
                placeholder="Press Enter to add tags & click Create"
                inputRef={ref}
                variant="outlined"
                sx={{ backgroundColor: "white" }}
              />
            )}
            renderTags={(value, getTagProps) => {
              return value.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  variant="outlined"
                  label={
                    <Typography variant="p" fontWeight="bold">
                      {option}
                    </Typography>
                  }
                  size="small"
                  sx={{
                    backgroundColor: generateColor(option),
                    color: "white",
                  }}
                />
              ));
            }}
          />
        )}
      />
      <Button
        variant="contained"
        type="submit"
        startIcon={loading ? <CircularProgress size="1rem" /> : <CreateIcon />}
      >
        Create
      </Button>
    </Box>
  );
};

export default CreateTags;
