import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useAppStore } from "../stores/appStore";
import AutomationLibraryTable from "../components/AutomationLibraryTable";
import axios from "axios";
import constants from "../utils/constants";

const AutomationLibrary = () => {
  const updatePageOpen = useAppStore((state) => state.updatePageOpen);
  const [automations, setAutomations] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    updatePageOpen("automation_library");
  }, []);

  const fetchAutomations = async () => {
    try {
      setLoading(true);

      const url = `${constants.backend_url}/automations/`;
      const res = await axios.get(url);
      setAutomations(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    fetchAutomations();
  }, []);

  const handleDeleteAutomation = async (id) => {
    try {
      let url = `${constants.backend_url}/automations/${id}`;
      let res = await axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      await fetchAutomations();
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <Box
      sx={{
        height: "calc(100vh - 112px)",
      }}
    >
      <AutomationLibraryTable
        automations={automations}
        needCheckBox={false}
        loading={loading}
        handleDeleteAutomation={handleDeleteAutomation}
      />
    </Box>
  );
};

export default AutomationLibrary;
