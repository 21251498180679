import { create } from "zustand";
import { persist } from "zustand/middleware";

let appStore = (set) => ({
  open: true,
  pageOpen: "",
  updateOpen: (open) => set((state) => ({ open: open })),
  updatePageOpen: (pageOpen) => set((set) => ({ pageOpen: pageOpen })),
});

appStore = persist(appStore, { name: "app_store" });
export const useAppStore = create(appStore);
