import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import { Box, Fab } from "@mui/material";
import { useNavigate } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";

const AddAgentCard = ({ agent, handleDeleteAgent }) => {
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        height: "100%",
        // minHeight: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CardContent
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Fab
            color="primary"
            size="large"
            sx={{ transform: "scale(1.5)", color: "#fff" }}
            onClick={() => navigate("/deploy")}
          >
            <AddIcon />
          </Fab>
          {/* <Button sx={{ borderRadius: "100%" }}></Button> */}
          {/* <IconButton>
          </IconButton> */}
        </Box>
      </CardContent>
    </Card>
  );
};

export default AddAgentCard;
