import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#00C5FF",
    },
    secondary: {
      main: "#494c7d",
    },
  },
  typography: {
    // fontFamily: "poppins, sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#fff",
        },
      },
    },
  },
});

export default theme;
