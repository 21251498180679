import { useForm, Controller, useFormContext } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import constants from "../utils/constants";
import axios from "axios";

const DeployAgentForm = ({ agent = {}, isEdit = false }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [tags, setTags] = useState([]);
  const [tagsLoading, setTagsLoading] = useState(true);
  const [tagsError, setTagsError] = useState(null);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        setTagsLoading(true);
        const url = `${constants.backend_url}/tags/`;
        const res = await axios.get(url);
        let tagNames = res.data.map((tag) => tag.name);
        setTags(tagNames);
        setTagsLoading(false);
      } catch (error) {
        setTagsLoading(false);
        setTagsError(error);
        console.log(error.response);
      }
    };
    fetchTags();
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
      <Box>
        <Typography variant="subtitle1">Agent Name</Typography>
        <Controller
          control={control}
          name="name"
          defaultValue=""
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              size="small"
              placeholder="Click to enter agent name"
              variant="outlined"
              sx={{ backgroundColor: "white" }}
              error={errors.name !== undefined}
            />
          )}
        />
        {errors.name !== undefined && (
          <Typography variant="body2" color="red">
            Agent name is required
          </Typography>
        )}
      </Box>

      <Box>
        <Typography variant="subtitle1">Agent Description</Typography>
        <Controller
          control={control}
          name="agent_description"
          defaultValue=""
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              size="small"
              placeholder="Click to enter agent description"
              variant="outlined"
              sx={{ backgroundColor: "white" }}
              multiline
              rows={6}
              error={errors.agent_description !== undefined}
            />
          )}
        />
        {errors.agent_description !== undefined && (
          <Typography variant="body2" color="red">
            Agent Description is required
          </Typography>
        )}
      </Box>

      <Box>
        <Typography variant="subtitle1">Function</Typography>
        <Controller
          control={control}
          name="function"
          defaultValue={isEdit ? agent.function : ""}
          rules={{ required: true }}
          render={({ field: { ref, onChange, ...field } }) => (
            <Autocomplete
              options={tags}
              loading={tagsLoading}
              onChange={(_, data) => onChange(data)}
              defaultValue={isEdit ? agent.function : undefined}
              // getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...field}
                  {...params}
                  fullWidth
                  size="small"
                  inputRef={ref}
                  variant="outlined"
                  sx={{ backgroundColor: "white" }}
                  placeholder="Select function"
                  error={errors.function !== undefined || tagsError !== null}
                />
              )}
            />
          )}
        />
        {errors.function !== undefined && (
          <Typography variant="body2" color="red">
            Function is required
          </Typography>
        )}
        {tagsError !== null && (
          <Typography variant="body2" color="red">
            Failed to fetch tags
          </Typography>
        )}

        <Button
          variant="contained"
          type="submit"
          sx={{ width: "100%", marginTop: 2 }}
          startIcon={<SupportAgentIcon />}
        >
          {isEdit ? "Save Agent" : "Deploy Agent"}
        </Button>
        <Controller
          control={control}
          name="automations"
          defaultValue={isEdit ? agent.automations : []}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              sx={{ display: "none" }}
              error={errors.automations !== undefined}
            />
          )}
        />
        {errors.automations !== undefined && (
          <Typography variant="body2" color="red">
            Automations are required
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default DeployAgentForm;
