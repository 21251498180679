import { Route, Routes } from "react-router-dom";
import AutomationLibrary from "./pages/AutomationLibrary";
import AddAutomation from "./pages/AddAutomation";
import DeployAgent from "./pages/DeployAgent";
import ManageAgents from "./pages/ManageAgents";
import Layout from "./components/Layout";
import Settings from "./pages/Settings";

function App() {
  return (
    <>
      <Layout>
        <Routes>
          <Route path="/" exact element={<AutomationLibrary />} />
          <Route
            path="/add/:automation_id?"
            exact
            element={<AddAutomation />}
          />
          <Route path="/deploy/:agent_id?" exact element={<DeployAgent />} />
          <Route path="/manage" exact element={<ManageAgents />} />
          <Route path="/settings" exact element={<Settings />} />
        </Routes>
      </Layout>
    </>
  );
}

export default App;
