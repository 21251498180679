import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppStore } from "../stores/appStore";
import { Controller, useForm } from "react-hook-form";
import CreateIcon from "@mui/icons-material/Create";
import { generateColor } from "../utils/generateRandomColor";
import constants from "../utils/constants";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateTags from "../components/CreateTags";
import DeleteTags from "../components/DeleteTags";

const Settings = () => {
  const updatePageOpen = useAppStore((state) => state.updatePageOpen);



  useEffect(() => {
    updatePageOpen("settings");
  }, []);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <CreateTags />
      <DeleteTags />
    </Box>
  );
};

export default Settings;
