import React, { useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Button, Chip, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationDialog from "./ConfirmationDialog";
import dayjs from "dayjs";
import { generateColor } from "../utils/generateRandomColor";

const AutomationLibraryTable = ({
  automations,
  needCheckBox,
  loading,
  handleDeleteAutomation,
  methods = {},
  addToForm = false,
  selectedAutomations = [],
}) => {
  const navigate = useNavigate();
  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 0.2,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 0.5,
    },
    {
      field: "created_at",
      headerName: "Created At",
      flex: 0.1,
      valueFormatter: (params) => {
        return dayjs(params.value).format("DD/MM/YYYY");
      },
    },
    {
      field: "tags",
      headerName: "Tags",
      flex: 0.2,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              // flexWrap: "wrap",
              alignItems: "center",
              gap: 2,
              overflowX: "scroll",
            }}
          >
            {params.value.map((tag) => {
              return (
                <Chip
                  key={tag}
                  label={
                    <Typography variant="p" fontWeight="bold">
                      {tag}
                    </Typography>
                  }
                  sx={{
                    backgroundColor: generateColor(tag),
                    color: "white",
                  }}
                />
              );
            })}
          </Box>
        );
      },
    },
    {
      field: "edit",
      sortable: false,
      headerName: "Edit",
      flex: 0.05,
      renderCell: (params) => {
        return (
          <IconButton
            color="primary"
            onClick={() => {
              const get_automation_by_id_url = `/add/${params.row._id}`;
              navigate(get_automation_by_id_url);
            }}
          >
            <EditIcon style={{ color: "primary.main" }} fontSize="small" />
          </IconButton>
        );
      },
    },
    {
      field: "delete",
      sortable: false,
      headerName: "Delete",
      flex: 0.05,
      renderCell: (params) => {
        return (
          <ConfirmationDialog
            trigger={
              <IconButton color="primary">
                <DeleteIcon fontSize="small" />
              </IconButton>
            }
            handleAgree={() => {
              handleDeleteAutomation(params.row._id);
            }}
            title="Automation Deletion"
            description="This action cannot be undone. Are you sure you want to delete?"
          />
        );
      },
    },
  ];

  const [rowSelectionModel, setRowSelectionModel] =
    useState(selectedAutomations);

  const handleRowSelection = (newRowSelectionModel) => {
    setRowSelectionModel(newRowSelectionModel);
    if (addToForm) {
      methods.setValue(
        "automations",
        newRowSelectionModel.map((id) => {
          return { _id: id };
        })
      );
    }
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer
        sx={{ display: "flex", justifyContent: "space-between", padding: 0.5 }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 2,
          }}
        >
          <Button
            variant="contained"
            size="small"
            startIcon={
              <AddIcon style={{ stroke: "#00C5FF", strokeWidth: 0.8 }} />
            }
            onClick={() => {
              navigate("/add");
            }}
          >
            Add Automation
          </Button>
          <GridToolbarColumnsButton />
        </Box>
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: "white",
        "& .MuiDataGrid-columnHeader": {
          backgrounColor: "#f3f4f8",
        },
        height: "100%",
      }}
    >
      <DataGrid
        getRowId={(row) => row._id}
        getRowHeight={() => "auto"}
        pageSizeOptions={[10, 25, 50, 100]}
        rows={automations}
        columns={columns}
        checkboxSelection={needCheckBox}
        slots={{ toolbar: CustomToolbar }}
        loading={loading}
        selectModel={selectedAutomations}
        onRowSelectionModelChange={handleRowSelection}
        rowSelectionModel={rowSelectionModel}
        sx={{
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "10px",
          },
          ".MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
        }}
      />
    </Box>
  );
};

export default AutomationLibraryTable;
