import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid } from "@mui/material";
import { useAppStore } from "../stores/appStore";
import DeployAgentForm from "../components/DeployAgentForm";
import AutomationLibraryTable from "../components/AutomationLibraryTable";
import axios from "axios";
import constants from "../utils/constants";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

const DeployAgent = () => {
  const updatePageOpen = useAppStore((state) => state.updatePageOpen);
  const [automations, setAutomations] = useState([]);
  const [agent, setAgent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const navigate = useNavigate();
  const params = useParams();

  const methods = useForm();

  let isEdit = false;
  if (Object.keys(params).includes("agent_id")) {
    isEdit = true;
  }

  const fetchAutomations = async () => {
    try {
      setLoading(true);

      const url = `${constants.backend_url}/automations/`;
      const res = await axios.get(url);
      setAutomations(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.response);
      setError(error);
    }
  };

  const fetchAgent = async () => {
    try {
      setLoading(true);
      const url = `${constants.backend_url}/agents/${params.agent_id}`;
      const res = await axios.get(url);

      setAgent(res.data);
      setLoading(false);
      methods.setValue("name", res.data.name);
      methods.setValue("agent_description", res.data.agent_description);
      methods.setValue("function", res.data.function);
    } catch (error) {
      setLoading(false);
      setError(error);
      console.log(error.response);
    }
  };
  const handleDeleteAutomation = async (id) => {
    try {
      let url = `${constants.backend_url}/automations/${id}`;
      let res = await axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      await fetchAutomations();
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    updatePageOpen("deploy_agent");
    fetchAutomations();
  }, []);

  useEffect(() => {
    if (Object.keys(params).includes("agent_id")) {
      fetchAgent();
    }
  }, [params]);

  const handleOnSubmit = async (evt) => {
    try {
      setLoading(true);

      if (isEdit) {
        let url = `${constants.backend_url}/agents/${params.agent_id}`;
        let res = await axios.put(
          url,
          { ...evt, endpoint: "" },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } else {
        let url = `${constants.backend_url}/agents/`;
        let res = await axios.post(
          url,
          { ...evt, endpoint: "" },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      }
      setLoading(false);
      navigate("/manage");
    } catch (error) {
      setLoading(false);
      setError(error);
      console.log(error.response);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <FormProvider sx={{ height: "100%" }} {...methods}>
      <Box
        component="form"
        onSubmit={methods.handleSubmit(handleOnSubmit)}
        sx={{ height: "100%" }}
      >
        <Grid
          container
          direction="row"
          columnSpacing={2}
          sx={{ height: "100%" }}
        >
          <Grid item xs={12} md={3}>
            {isEdit === true ? (
              agent ? (
                <DeployAgentForm agent={agent} isEdit={true} />
              ) : (
                <></>
              )
            ) : (
              <DeployAgentForm />
            )}
          </Grid>
          <Grid item xs={12} md={9}>
            {isEdit ? (
              agent !== null && automations.length > 0 ? (
                <AutomationLibraryTable
                  automations={automations}
                  needCheckBox={true}
                  handleDeleteAutomation={handleDeleteAutomation}
                  methods={methods}
                  addToForm={true}
                  selectedAutomations={[
                    ...agent.automations.map((id) => id._id),
                  ]}
                />
              ) : (
                <></>
              )
            ) : (
              <AutomationLibraryTable
                automations={automations}
                needCheckBox={true}
                loading={loading}
                handleDeleteAutomation={handleDeleteAutomation}
                methods={methods}
                addToForm={true}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  );
};

export default DeployAgent;
