import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { useAppStore } from "../stores/appStore";
import AgentsList from "../components/AgentsList";
import constants from "../utils/constants";
import axios from "axios";

const ManageAgents = () => {
  const updatePageOpen = useAppStore((state) => state.updatePageOpen);



  useEffect(() => {
    updatePageOpen("manage_agents");
  }, []);

  return (
    <>
      <Box sx={{ height: "100%" }}>
        <AgentsList />
      </Box>
    </>
  );
};

export default ManageAgents;
