import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import { useAppStore } from "../stores/appStore";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PeopleIcon from "@mui/icons-material/People";
import SettingsIcon from "@mui/icons-material/Settings";

const drawerWidth = 260;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Sidebar = () => {
  const navigate = useNavigate();

  const open = useAppStore((state) => state.open);
  const pageOpen = useAppStore((state) => state.pageOpen);

  const routes = [
    { name: "Automation Library", routeUrl: "/", state: "automation_library" },
    { name: "Add Automation", routeUrl: "/add", state: "add_automation" },
    { name: "Deploy Agent", routeUrl: "/deploy", state: "deploy_agent" },
    { name: "Manage Agents", routeUrl: "/manage", state: "manage_agents" },
    { name: "Settings", routeUrl: "/settings", state: "settings" },
  ];

  const getIcon = (state) => {
    switch (state) {
      case "automation_library":
        return (
          <LibraryBooksIcon
            sx={{ color: pageOpen === state ? "primary.main" : "unset" }}
          />
        );
      case "add_automation":
        return (
          <LibraryAddIcon
            sx={{ color: pageOpen === state ? "primary.main" : "unset" }}
          />
        );
      case "deploy_agent":
        return (
          <PersonAddAlt1Icon
            sx={{ color: pageOpen === state ? "primary.main" : "unset" }}
          />
        );
      case "manage_agents":
        return (
          <PeopleIcon
            sx={{ color: pageOpen === state ? "primary.main" : "unset" }}
          />
        );
      case "settings":
        return (
          <SettingsIcon
            sx={{ color: pageOpen === state ? "primary.main" : "unset" }}
          />
        );
      default:
        return (
          <LibraryBooksIcon
            sx={{ color: pageOpen === state ? "primary.main" : "unset" }}
          />
        );
    }
  };

  return (
    <Box sx={{ display: "flex", fontFamily: "glancyrregular" }}>
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <Box height={64} />
        <List>
          {routes.map((route, index) => (
            <ListItem
              key={route.name}
              disablePadding
              sx={{ display: "block" }}
              onClick={() => {
                navigate(route.routeUrl);
              }}
            >
              {route.state === "settings" ? <Divider /> : null}
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  backgroundColor:
                    pageOpen === route.state
                      ? "rgba(0, 197, 255, 0.05)"
                      : "unset",
                  marginX: "6px",
                  borderRadius: "4px",
                  borderLeft: pageOpen === route.state ? 3 : "unset",
                  borderLeftColor:
                    pageOpen === route.state ? "#00C5FF" : "unset",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {getIcon(route.state)}
                </ListItemIcon>
                <ListItemText
                  primary={route.name}
                  sx={{
                    opacity: open ? 1 : 0,
                    color: pageOpen === route.state ? "#0087AC" : "unset",
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  );
};
export default Sidebar;
