import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../utils/authConfig";
import { Button } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";

function handleRedirectLogin(instance) {
  instance.loginRedirect(loginRequest).catch((e) => {
    console.error(e);
  });
}

function handlePopupLogin(instance) {
  instance.loginPopup(loginRequest).catch((e) => {
    console.error(e);
  });
}

const Login = () => {
  const { instance } = useMsal();

  return (
    <Button
      variant="contained"
      type="submit"
      // size="small"
      startIcon={<LoginIcon />}
      onClick={() => {
        handleRedirectLogin(instance);
      }}
      //   sx={{ minWidth: "max-content", width: "20%" }}
    >
      Login
    </Button>
  );
};

export default Login;
