import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../utils/authConfig";
import { Button } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";

function handleRedirectLogout(instance) {
  instance.logoutRedirect(loginRequest).catch((e) => {
    console.error(e);
  });
}

function handlePopupLogout(instance) {
  instance.logoutPopup(loginRequest).catch((e) => {
    console.error(e);
  });
}

const Logout = () => {
  const { instance } = useMsal();

  return (
    <Button
      variant="contained"
      type="submit"
      startIcon={<LogoutIcon />}
      // size="small"a
      onClick={() => {
        handleRedirectLogout(instance);
      }}
      //   sx={{ minWidth: "max-content", width: "20%" }}
    >
      Logout
    </Button>
  );
};

export default Logout;
