import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import {
  Box,
  Chip,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationDialog from "./ConfirmationDialog";
import dayjs from "dayjs";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useState } from "react";
import { generateColor } from "../utils/generateRandomColor";

const AgentCard = ({ agent, handleDeleteAgent }) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = (event) => {
    if (event.target.outerText !== "Delete") {
      setAnchorEl(null);
    }
  };

  const handleEditItemClick = () => {
    const get_agent_by_id_url = `/deploy/${agent._id}`;
    navigate(get_agent_by_id_url);
  };

  return (
    <Card
      sx={{
        height: "100%",
        // minHeight: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CardHeader
        avatar={<Avatar alt="Remy Sharp" src="/assets/man.svg" />}
        action={
          <>
            <IconButton onClick={handleMenu} size="small">
              <MoreVertIcon />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleMenuClose}
              onClick={handleMenuClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={handleEditItemClick} key="edit">
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </MenuItem>
              <ConfirmationDialog
                trigger={
                  <MenuItem key="delete">
                    <ListItemIcon>
                      <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                  </MenuItem>
                }
                handleAgree={() => {
                  handleDeleteAgent(agent._id);
                }}
                title="Agent Deletion"
                description={`This action cannot be undone. Are you sure you want to delete ${agent.name}?`}
              />
            </Menu>
          </>
        }
        title={<Box sx={{ fontFamily: "glancyrregular" }}>{agent.name}</Box>}
        titleTypographyProps={{ variant: "h6" }}
        subheader={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CalendarMonthIcon style={{ fontSize: "1rem" }} />
            <Typography variant="body2" sx={{ paddingX: 1 }}>
              {dayjs(agent.created_at).format("DD/MM/YYYY")}
            </Typography>
          </Box>
        }
      />
      <CardContent
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 4,
        }}
      >
        <Box>
          <Typography variant="body1" color="text.secondary">
            Description
          </Typography>
          <Typography variant="body2">{agent.agent_description}</Typography>
        </Box>
        <Box>
          <Typography variant="body1" color="text.secondary">
            Endpoint
          </Typography>
          <Typography variant="body2">{agent.endpoint}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="body1" color="text.secondary">
            Function
          </Typography>
          <Chip
            label={
              <Typography variant="p" fontWeight="bold">
                {agent.function}
              </Typography>
            }
            sx={{
              backgroundColor: generateColor(agent.function),
              color: "white",
            }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default AgentCard;
